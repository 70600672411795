body.blogcommon {
	.mi-wrapper {
		background: url(../img/common/mainImage_blogcommon.jpg) top center no-repeat;
	}
}

/* CONTENTS MODULE COMMON
------------------------------ */
.contents {
	min-height: 600px;
	height: auto !important;
	height: 600px;
}

// .sidebar {
// 	min-height: 800px;
// 	height: auto !important;
// 	height: 800px;
// }

body.col2 {
	.contents {
		width: 760px;
		// border-right: 1px solid #ccc; // for test
		float: right;
		display: inline;
		zoom: 1;
		.subTitle {
			width: 730px;
			line-height: 38px;
			background: url(../img/common/bd_pageTitle_r760.gif) 0 0 no-repeat;
			padding: 0 20px 0 10px;
			margin-bottom: 10px;
			h3 {
				font-size: 20px;
				color: #fff;
				padding-left: 24px;
				background: url(../img/common/bullet_circle_wr.gif) 0 50% no-repeat;
			}
		}

	}
	.sidebar {
		width: 240px;
		float: left;
		display: inline;
		zoom: 1;
		.sideTitle {
			margin-right: 20px;
			margin-bottom: 25px;
		}
		.sideSub {
			margin-left: 22px;
			margin-bottom: 15px;
		}
		ul {
			margin-bottom: 32px;
			margin-left: 19px;
			margin-right: 19px;
			li {
				line-height: 1.4;
				margin-bottom: 15px;
				a {
					text-decoration: underline;
				}
			}
		&.localNavi {

		}
		&.localNavi2.forCategory {
			li {
				color: #0000ff;
				margin-left: 1.6em;
				list-style-type: disc;
			}
		}
		}
		.monthlySelector {
			margin-left: 11px;
			margin-bottom: 32px;
			form {
				select {
					width: 178px;
				}
			}
		}
	}
}

/* FOR POST LIST PAGES
   INDEX, CATEGORY, ARCHIVE
------------------------------ */
body.postList {
	.contents {
		.section {
			padding-bottom: 100px;
		}
		.post {
			width: 690px;
			border: 1px solid #c00;
			padding: 35px 34px;
			margin-bottom: 40px;
			h4.postTitle {
				font-size: 24px;
				color: #c00;
				font-weight: bold;
				margin-bottom: 15px;
				line-height: 1.0;
			}
			.postThumb {
				margin-bottom: 27px;
			}
			p.postMeta {
				font-size: 14px;
				margin-bottom: 15px;
				line-height: 1.0;
				span {
					color: #999;
					margin-right: 20px;
				}
				a {
					text-decoration: underline;
				}
			}
			.postExcerpt {
				font-size: 16px;
				line-height: 1.66;
				margin-bottom: 15px;
			}
			.postMore {
				width: 130px;
				height: 46px;
				background-color: #f3f3f3;
				text-align: center;
				p {
					line-height: 46px;
					a {
						font-size: 16px;
						color: #666;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

/* PAGER SETTINGS
------------------------------ */
ul.pager {
	padding-top: 4px;
	margin-left: 379px;
	width: 372px;
	height: 38px;
	li {
		width: 154px;
		float: left;
		display: inline;
		zoom: 1;
	&.prevLink {
		margin-right: 64px;
	}
	}
}

p.gotoList {
	width: 154px;
	height: 38px;
	float: left;
	display: inline;
	zoom: 1;
	margin-top: -38px;
}
/* FOR SINGLE PAGES
------------------------------ */
body.postSingle {
	.contents {
		.section {
			padding-bottom: 100px;
		}
		.postHeader {
			border: 1px solid #c00;
			border-bottom: none;
			padding: 25px 34px;
			h3.postTitle {
				font-size: 24px;
				color: #c00;
				font-weight: bold;
				line-height: 1.0;
			}
			p.postMeta {
				font-size: 14px;
				margin-bottom: 8px;
				line-height: 1.0;
				span {
					color: #999;
					margin-right: 20px;
				}
				a {
					text-decoration: underline;
				}
			}
		}
		.post {
			width: 690px;
			border: 1px solid #c00;
			padding: 35px 34px;
			margin-bottom: 40px;

			.postThumb {
				margin-bottom: 27px;
			}
			.postBody {
				font-size: 16px;
				line-height: 1.66;
				margin-bottom: 15px;
			}
			.postMore {
				width: 130px;
				height: 46px;
				background-color: #f3f3f3;
				text-align: center;
				p {
					line-height: 46px;
					a {
						font-size: 16px;
						color: #666;
						text-decoration: underline;
					}
				}
			}
		}
	}
}


/* SOCIAL BUTTON
------------------------------ */
ul.socialButton {
	// margin-left: 20px;
	margin-bottom: -30px;
	margin-bottom: 26px;
	li {
		float: left;
		display: inline;
		zoom: 1;
		// vertical-align: middle;
		line-height: 25px;
		&.twitter {
			margin-right: 10px;
		}
		&.facebook_like {
			margin-right: -50px;
		}
	}
}

body.postSingle {
	.contents {
		.post {
			.postSocial {
				padding-top: 20px;
			}
		}
	}
}



/* FOR SINGLE COLUMN PAGE
------------------------------ */
body.col1 {
	.contents {
		.section {
			padding-bottom: 100px;
		}
		.postHeader {
			border: 1px solid #c00;
			border-bottom: none;
			padding: 25px 34px;
			h3.postTitle {
				font-size: 24px;
				color: #c00;
				font-weight: bold;
				line-height: 1.0;
			}
			p.postMeta {
				font-size: 14px;
				margin-bottom: 8px;
				line-height: 1.0;
				span {
					color: #999;
					margin-right: 20px;
				}
				a {
					text-decoration: underline;
				}
			}
		}
		.post {
			min-height: 400px;
			height: auto !important;
			height: 400px;
			width: 930px;
			border: 1px solid #c00;
			padding: 35px 34px;
			margin-bottom: 40px;
			.postThumb {
				margin-bottom: 27px;
			}
			.postBody {
				font-size: 16px;
				line-height: 1.66;
				// margin-bottom: 15px;
				a {
					text-decoration: underline;
				}
			}
			.postMore {
				width: 130px;
				height: 46px;
				background-color: #f3f3f3;
				text-align: center;
				p {
					line-height: 46px;
					a {
						font-size: 16px;
						color: #666;
						text-decoration: underline;
					}
				}
			}
		}
	}
}





/* SOCIAL BUTTON
------------------------------ */
// ul.socialButton {
// 	// margin-left: 20px;
// 	margin-bottom: -30px;
// 	margin-bottom: 26px;
// 	li {
// 		float: left;
// 		display: inline;
// 		zoom: 1;
// 		// vertical-align: middle;
// 		line-height: 25px;
// 		&.twitter {
// 			margin-right: 10px;
// 		}
// 		&.facebook_like {
// 			margin-right: -50px;
// 		}
// 	}
// }

.wp_social_bookmarking_light {
	.wsbl_facebook_like {
		width: 100px;
		float: left;
		display: inline;
		zoom: 1;
	}
}